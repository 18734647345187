const QGIV = QGIV || {};

function EventLanding() {

    function bodyScrollHandler(isCms) {
        const image = document.querySelector(".header-image-container");
        const hasImage = $(image).children().find(".header-image").length > 0;
        const header = document.querySelector(".header-details-container");
        const mainContent = document.querySelector(".js-main-content");
        let isSticky = false;
        return () => {
            const imageBounds = image.getBoundingClientRect();
            const imageBottom = imageBounds.bottom;
            const headerBounds =  header.getBoundingClientRect();
            const mainContentBounds = mainContent.getBoundingClientRect();
            const mainHeight = headerBounds.height + mainContentBounds.height;
            const windowHeight = window.innerHeight;
            const windowResizedWithImage = hasImage && isSticky && headerBounds.bottom > imageBottom;
            // add some padding here to prevent jumping if it's really close
            if (((windowResizedWithImage || !isSticky ) && mainHeight <= windowHeight + 10) || (!hasImage && isSticky && headerBounds.y > imageBounds.y)) {
                return;
            };

            if (imageBottom < 1 && isSticky) {
                return;
            }

            if (hasImage && imageBottom >= 0 && !isSticky) {
                return;
            }
            if (!hasImage && imageBounds.top === 0 && !isSticky) {
                return;
            }
            // This is needed to handle when there is less content but could technically be sticky
            // We need to prevent changing to sticky in order to avoid flashing between the two states
            if (!isSticky && mainHeight <= windowHeight + 200) {
                return;
            }

            if ( (hasImage && imageBottom < 0 && !isCms) || (!hasImage && imageBottom < 20 && !isCms) ) {
                header.classList.add("sticky-top");
                isSticky = true;
                // get the height again as the sticky header would have changed it on mobile
                const stickyHeaderBounds =  header.getBoundingClientRect();
                mainContent.style.marginTop = `${stickyHeaderBounds.height}px`;
                return;
            }
            if (isCms) {
                header.style.width = `${imageBounds.width}px`;
                return;
            }
            header.classList.remove("sticky-top");
            mainContent.style.marginTop = "0";
            isSticky = false;
        };
    }

    function initStickyHeader() {
        bodyScrollHandler(false);
        const cms = document.querySelector(".cms-enabled");
        if (cms) {
            document
                .querySelector(".event-landing-page-container")
                .addEventListener("scroll", bodyScrollHandler(true));
            return;
        }
        document.addEventListener("scroll", bodyScrollHandler(false));
    }

    function initEmbedRenders() {
        // Event List Widgets are embedded iframes and they are slow to
        // be added to the DOM and are missing in the NodeList on init.
        if (QGIV.Embed) {
            QGIV.embeds = QGIV.Embed.getEmbeds();
            QGIV.Embed.renderEmbeds();
        }
    }

    function checkForLaunchModal() {
        const urlParams = new URLSearchParams(window.location.search);
        const launchModal = urlParams.has('launchmodal');
        if (launchModal) {
            const selector = QGIV.Form.type === "auction" ?
                ".js-display-registration-button > button" :
                ".qgiv-load-modal";

            const $registerButton = $(selector);
            const eventUrl = $registerButton.attr("data-event-url");
            QGIV.Embed.loadFullScreenModal(null, { eventUrl, eventName: "Registration" });
        }
    }



    function updateDetailsStyle() {
        const $detailsContainer = $(".js-details-container:visible");

        const detailsContainerWidth = $detailsContainer.width();

        if (detailsContainerWidth > 400) {
            $detailsContainer.addClass("ui-details-column-container");
        } else {
            $detailsContainer.removeClass("ui-details-column-container");
        }
    }

    function bindEventsHandlers() {
        // Trigger main registration button click event
        $(".js-registration-link").on("click keypress", function() {
           if ($(".qgiv-load-modal").length) {
               $(".qgiv-load-modal").trigger("click");
           };
        });
        $(".js-details-container:visible").resize(function() {
            updateDetailsStyle();
        });
   }

    function init() {
        initStickyHeader();
        checkForLaunchModal();
        bindEventsHandlers();
        updateDetailsStyle();

        setTimeout(() => {
            initEmbedRenders();
        }, 50)

    }

    return {
        init: init,
    };
}

const eventLanding = new EventLanding();

$(eventLanding.init);
